import React from "react"
import styled from "styled-components"
import Layout from "../components/Layout"
import { graphql } from "gatsby"
import ReactMarkdown from "react-markdown"
import Hero from "../components/HeroTwoCenter"
import { injectIntl, FormattedMessage } from "gatsby-plugin-intl"


const nosotros = ({ data, intl}) => {


  let nombre = '';
  let mensaje = '';
  let titleNosotros = '';
 
  const {node_locale} = data.allContentfulNosotros.nodes[0]
  node_locale === "en" ? nombre = 'Name' : nombre = "Nombre"
  node_locale === "en" ? mensaje = 'Message' : mensaje = "Mensaje"
  node_locale === "en" ? titleNosotros = "Our Ministry" : titleNosotros = "Nosotros"

  


  const {
    contenido,
  } = data.allContentfulNosotros.nodes[0].childContentfulNosotrosContenidoTextNode

  return (
    <>
      <Layout>
        <Hero content={titleNosotros} image={data.file}></Hero>
        <Div>
          <Wrapper>
            <ReactMarkdown>{contenido}</ReactMarkdown>
            <ContactPage>
              <ContactForm>
                <Heading><FormattedMessage id = "contactanos"/> </Heading>
                <FormGroup
                  action="https://formspree.io/f/xdoylldn"
                  method="POST"
                >
                  <FormContent>
                    <InputText
                      name= {nombre}
                      type="text"
                      placeholder= {nombre}
                    ></InputText>
                    <EmailText
                      name="email"
                      type="email"
                      placeholder="email"
                    ></EmailText>
                    <TextArea name={mensaje} placeholder={mensaje} rows="5" />
                    <SubmitButton type="submit"><FormattedMessage id = "enviar"/></SubmitButton>
                  </FormContent>
                </FormGroup>
              </ContactForm>
            </ContactPage>
          </Wrapper>
        </Div>
      </Layout>
    </>
  )
}

export const query = graphql`
query getNosotros($locale: String) {
  file(relativePath: {eq: "beauty-sky.jpg"}) {
    childImageSharp {
      fluid(maxWidth: 2048, quality: 90) {
        ...GatsbyImageSharpFluid_noBase64
      }
    }
  }
  
  allContentfulNosotros(filter: {node_locale: {eq: $locale}}) {
    nodes {
      childContentfulNosotrosContenidoTextNode {
        contenido
      }
      titulo
      node_locale
    }
  }
}
`

const Div = styled.div`
  display: flex;
  height: 100%;
  background: ${({ theme }) => theme.colorGrey10};
`

const Wrapper = styled.section`
  background: ${({ theme }) => theme.colorGrey10};
  width: 90vw;
  max-width: 600px;
  padding-top:4rem;
  margin: 0 auto;;
`

/* Contact Page */

const Heading = styled.h3`
  font-family: ${({ theme }) => theme.primaryFont};
  padding-top: 1.25rem;
  color: ${({ theme }) => theme.text};
`

const ContactPage = styled.section`
  display: grid;
  place-items: center;
  padding: 5rem 0;
  background: ${({ theme }) => theme.colorGrey10};
`
const ContactForm = styled.form`
  background: ${({ theme }) => theme.colorWhite};
  border-radius: ${({ theme }) => theme.radius};
  text-align: center;
  box-shadow: ${({ theme }) => theme.lightShadow};
  transition: ${({ theme }) => theme.transition};
  width: 90vw;
  max-width: 35rem;
  :hover {
    box-shadow: ${({ theme }) => theme.darkShadow};
  }
`
const FormGroup = styled.form`
  padding: 1rem 1.5rem;
`
const FormContent = styled.div`
  padding: 1rem 1.5rem;
`
const InputText = styled.input`
  ::placeholder {
    color: ${({ theme }) => theme.text};
  }
  color: ${({ theme }) => theme.text};
  font-family: ${({ theme }) => theme.primaryFont};
  display: block;
  width: 100%;
  padding: 0.75rem 1rem;
  border: none;
  margin-bottom: 1.25rem;
  background: ${({ theme }) => theme.colorGrey10};
  border-radius: ${({ theme }) => theme.radius};
  text-transform: uppercase;
  letter-spacing: ${({ theme }) => theme.spacing};
`
const EmailText = styled(InputText)`
  color: ${({ theme }) => theme.text};
  ::placeholder {
    color: ${({ theme }) => theme.text};
  }
`
const TextArea = styled.textarea`
  color: ${({ theme }) => theme.text};
  ::placeholder {
    color: ${({ theme }) => theme.text};
  }
  font-family: ${({ theme }) => theme.primaryFont};
  display: block;
  width: 100%;
  padding: 0.75rem 1rem;
  border: none;
  margin-bottom: 1.25rem;
  background: ${({ theme }) => theme.colorGrey10};
  border-radius: ${({ theme }) => theme.radius};
  text-transform: uppercase;
  letter-spacing: ${({ theme }) => theme.spacing};
`

const SubmitButton = styled.button`
  display: block;
  width: 100%;
  padding: 1rem;
  margin-left: 1rem;
  border-bottom-left-radius: ${({ theme }) => theme.radius};
  border-bottom-right-radius: ${({ theme }) => theme.radius};
  border-top-right-radius: 0;
  border-top-left-radius: 0;
`

export default injectIntl(nosotros)
